<template>
  <div>
    <SearchBar></SearchBar>
    <div class="ui divider"></div>
    <div class="ui two column centered grid">
      <div id="player"></div>
      <div class="row">
        <div class="sixteen wide column ab-bar-bg">
          <div class="ab-bar" :style="abBarStyle"></div>
        </div>
      </div>
      <!-- <div>{{currentTime}}</div> -->
      <div class="row">
        <button class="ui huge button" @click="toggleLoopSetting">
          {{toggleButtonHint}}
        </button>
        <button class="ui icon button" :class="{yellow: isBookmarked}" @click="toggleBookmark">
          <i class="bookmark icon"></i>
        </button>
        <button :disabled="!looping" class="ui primary icon button" @click="uploadLoop">
          <i class="save icon"></i>
          {{$t('Save')}}
        </button>
        <!--
        <div class="ui huge buttons">
          <button class="ui green button" :class="{basic:a==undefined}" @click="setA">A</button>
          <button class="ui blue button" :class="{basic:b==undefined}" @click="setB">B</button>
        </div>
        -->
      </div>
      <div class="row">
        <div class="ui buttons" >
          <button class="ui green button" :class="{disabled:a==undefined}" @click="shiftA(-1)"><i class="left arrow icon"></i>A</button>
          <button class="ui green button" :class="{disabled:a==undefined}" @click="shiftA(1)">A<i class="right arrow icon"></i></button>
          <button class="ui blue button" :class="{disabled:b==undefined}" @click="shiftB(-1)"><i class="left arrow icon"></i>B</button>
          <button class="ui blue button" :class="{disabled:b==undefined}" @click="shiftB(1)">B<i class="right arrow icon"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="ui buttons" >
          <button class="ui olive button" :class="{disabled:a==undefined||b==undefined}" @click="shiftB(-1);shiftA(-1);"><i class="left arrow icon"></i>{{$t('Both AB')}}</button>
          <button class="ui olive button" :class="{disabled:a==undefined||b==undefined}" @click="shiftB(1);shiftA(1);">{{$t('Both AB')}}<i class="right arrow icon"></i></button>
        </div>
      </div>
      <div v-if="supportPlaybackRate" class="row">
        <div class="ui right pointing label">
          {{$t('Speed')}}
        </div>
        <div class="ui buttons">
          <button class="ui button" :class="{active: playbackRate==0.25}" @click="changePlaybackRate(0.25)">0.25</button>
          <button class="ui button" :class="{active: playbackRate==0.5}" @click="changePlaybackRate(0.5)">0.5</button>
          <button class="ui button" :class="{active: playbackRate==0.75}" @click="changePlaybackRate(0.75)">0.75</button>
          <button class="ui button" :class="{active: playbackRate==1}" @click="changePlaybackRate(1)">1</button>
        </div>
      </div>
      <div class="ui labels">
        <a v-for="(loop, index) in loops" class="ui label" :key="loop.id" @click="applyLoop(loop)" :class="{ 'basic blue': ownTheLoop(loop) }">{{loop.name}}
          <i v-if="ownTheLoop(loop)" class="delete icon" @click.stop="deleteLoop(loop, index)"></i>
        </a>
      </div>
    </div>
    <div class="ui mini modal" ref="uploadLoopModal">
      <div class="header">{{$t('Save and Share Loop')}}</div>
      <div class="content">
        <div class="ui fluid input">
          <input type="text" :placeholder="$t('Naming the loop, such as, Gtr. solo', {keySeparator:'%'})" v-model="loopName" maxlength="32" autofocus>
        </div>
        <div class="ui warning message">
          {{$t('Loop is public, other users can see the loops you created.', {keySeparator:'%'})}}
        </div>
      </div>
      <div class="actions">
        <button class="ui small cancel button">{{$t('Cancel')}}</button>
        <button class="ui small primary approve button" :disabled="loopName === undefined">{{$t('Save')}}</button>
      </div>
    </div>
    <div class="ui modal" ref="confirmModal">
      <div class="header">{{$t('Remove Loop')}}</div>
      <div class="content">
        <p>{{$t('Are you sure you want to remove this loop?')}}</p>
      </div>
      <div class="actions">
        <div class="ui cancel button">{{$t('Cancel')}}</div>
        <div class="ui red approve button">{{$t('Remove')}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import SearchBar from './SearchBar';
import firebase from 'firebase/app';
import 'firebase/auth';
import { updateHistory } from '../history';
import { uploadLoop, getTopLoops, removeLoop } from '../loops';
import { addBookmark, removeBookmark, isBookmarked } from '../bookmarks';

const ga = window.ga;

function mappingYouTubeState(youtubeState) {
  // Possible values are:
  // -1 – unstarted
  // 0 – ended
  // 1 – playing
  // 2 – paused
  // 3 – buffering
  // 5 – video cued
  switch (youtubeState) {
    case -1:
      return "Idle";
    case 0:
      return "Ended";
    case 1:
      return "Playing";
    case 2:
      return "Paused";
    case 3:
      return "Buffering";
    default:
      // eslint-disable-next-line
      console.log("find no mapping for state: " + youtubeState);
      return "unknown";
  }
}

export default {
  name: "YouTubePlayer",
  data () {
    return {
      videoId: undefined,
      currentTime: undefined,
      duration: undefined,
      a: undefined,
      b: undefined,
      playbackRate: 1,
      supportPlaybackRate: false,
      historyIsUploaded: false,
      loops: [],
      isBookmarked: false,
// upload loop form
      loopName: undefined,
    };
  },
  computed: {
    looping () {
      return this.a !== undefined && this.b !== undefined;
    },
    toggleButtonHint () {
      const {a, b} = this;
      if (a === undefined) {
        return this.$t('Tap to set A');
      }
      if (b === undefined) {
        return this.$t('Tap to set B');
      }
      return this.$t('Tap to reset');
    },
    abBarStyle () {
      const style = {
        'height': '100%',
        'min-width': '4px',
        'border-radius': '4px',
        'margin-left': '0%',
        'margin-right': '0%',
        'padding': '0px',
      };      
      if (this.duration) {
        const {a, b, duration} = this;
        if (a != undefined) {
          style['margin-left'] = `${Math.floor(a*100.0/duration)}%`;
          style['background-color'] = '#21ba45';
        }
        if (b != undefined) {
          style['margin-right'] = `${Math.ceil((duration-b)*100.0/duration)}%`;
          style['background-image'] = 'linear-gradient(to right, #21ba45 , #2185d0)';
        }
      }
      return style;
    }
  },
  mounted () {
    this.play();
    setInterval(() => {
      this.currentTime = this.player && this.player.getCurrentTime && this.player.getCurrentTime();
    }, 500);
  },
  watch: {
    currentTime (val) {
      const { a, b } = this;
      if (val >= b) {
        this.player && this.player.seekTo(a, true);
      }
    },
    '$route': function() {
      this.currentTime = undefined;
      if (this.videoId !== this.$route.params.videoId) {
        this.play();
      }
    },
    a () {
      this.$router.replace({ query: { a: this.a, b: this.b }});
    },
    b () {
      this.$router.replace({ query: { a: this.a, b: this.b }});
    },
  },
  methods: {
    play () {
      const videoId = this.$route.params.videoId; // "vo0vUn9ZumY"
      this.videoId = videoId;
      this.a = isNaN(parseInt(this.$route.query.a))?undefined:parseInt(this.$route.query.a);
      this.b = isNaN(parseInt(this.$route.query.b))?undefined:parseInt(this.$route.query.b);
      // eslint-disable-next-line
      console.log(`play`, videoId, this.$route.query);
      this.initPlayerIfNeeded().then(() => this.loadVideoById(videoId));
    },
    changePlaybackRate (playbackRate) {
      this.player && this.player.setPlaybackRate(playbackRate);
    },
    shiftA (offset) {
      if (this.a !== undefined) {
        this.a += offset;
        this.a = Math.max(0, this.a);
        if (this.b) {
          this.a = Math.min(this.b - 1, this.a);
        }
        if (this.duration) {
          this.a = Math.min(Math.floor(this.duration), this.a);
        }
        this.player && this.player.seekTo(this.a, true);
      }
    },
    shiftB (offset) {
      if (this.b !== undefined) {
        this.b += offset;
        if (this.a) {
          this.b = Math.max(this.a + 1, this.b);
        }
        if (this.duration) {
          this.b = Math.min(Math.floor(this.duration), this.b);
        }
        this.player && this.player.seekTo(Math.max(this.b - 2, 0), true);
      }
    },
    setA () {
      if (this.a !== undefined) {
        this.a = undefined;
        return;
      }
      this.a = Math.round(this.player && this.player.getCurrentTime && this.player.getCurrentTime());
    },
    setB () {
      if (this.b !== undefined) {
        this.b = undefined;
        return;
      }
      this.b = Math.round(this.player && this.player.getCurrentTime && this.player.getCurrentTime());
    },
    initPlayerIfNeeded () {
      return new Promise(resolve => {
        if (window.YT) {
          return resolve(window.YT);
        }
        // 2. This code loads the IFrame Player API code asynchronously.
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = function() {
          resolve();
        };
      });
    },
    onStateChange (event) {
      // eslint-disable-next-line
      console.log(`onStateChange`, mappingYouTubeState(event.data));
      if (event.data === 0) { // ended
        const { a } = this;
        this.player && this.player.seekTo(a || 0, true);
      }
      if (event.data === 1 && !this.historyIsUploaded) { //playing
          const {title, video_id} = this.player.getVideoData();
          document.title = `re:Play - ${title}`;
          updateHistory({
            videoId: video_id,
            title,
            userId: firebase.auth().currentUser.uid,
          });
          this.historyIsUploaded = true;
          // TODO: consider the timing to update duration
          this.duration = this.player.getDuration();
      }
    },
    onPlaybackRateChange (event) {
      // eslint-disable-next-line
      console.log(`onPlaybackRateChange`, event.data);
      this.playbackRate = event.data;
    },
    loadVideoById (videoId) {
      isBookmarked({
        videoId,
        userId: firebase.auth().currentUser.uid,
      }).then((isBookmarked) => this.isBookmarked = isBookmarked);

      this.loops = [];
      getTopLoops({ videoId }).then((querySnapshot) => { 
        querySnapshot.forEach((doc) => {
          this.loops.push(Object.assign({}, { id: doc.id }, doc.data()));
        });
      });
      this.historyIsUploaded = false;
      this.duration = undefined;
      if (this.player) {
        return this.player.loadVideoById({
          videoId,
          startSeconds: Math.round(this.a),
        });
      }
      const { YT } = window;
      this.player = new YT.Player("player", {
        videoId: videoId,
        events: {
          // eslint-disable-next-line no-unused-vars
          onReady: event => {
            // event.target.playVideo();
            this.supportPlaybackRate = this.player.getAvailablePlaybackRates().length > 1;
          },
          // 'onError': ,
          onStateChange: (event) => this.onStateChange(event),
          onPlaybackRateChange: (event) => this.onPlaybackRateChange(event),
        },
        playerVars: {
          loop: 1,
          playsinline: 1,
          start: Math.round(this.a),
        },
      });
    },
    toggleLoopSetting () {
      const {a, b} = this;
      if (a == undefined) {
        return this.setA();
      }
      if (b == undefined) {
        return this.setB();
      }
      this.a = this.b = undefined;
    },
    uploadLoop () {      
      this.showUploadLoopModal();
    },
    showUploadLoopModal () {
      ga('send', {
        hitType: 'event',
        eventCategory: 'looper',
        eventAction: 'showUploadLoopModal',
      });
      window.$(this.$refs.uploadLoopModal).modal({
        closable: false,
        onDeny : () => {},
        onApprove : () => {
          this.doUploadLoop();
        },
      })
      .modal('show');
    },
    doUploadLoop () {
      const { videoId, a, b, loopName } = this;
      if (a !== undefined && b !== undefined) {
        const {title} = this.player.getVideoData();
        uploadLoop({
          user: {
            userId: firebase.auth().currentUser.uid,
          },
          video: {
            videoId,
            title,
          },
          a,
          b,
          name: loopName,
        }).then((loop) => {
          this.loops.push(loop);
        });
      }
      this.loopName = undefined;
    },  
    applyLoop (loop) {
      this.a = loop.a;
      this.b = loop.b;
      this.player.seekTo(this.a, true);
    },
    deleteLoop (loop, index) {
      window.$(this.$refs.confirmModal).modal({
        closable: false,
        onDeny : () => {},
        onApprove : () => {
          removeLoop(loop);
          this.loops.splice(index, 1);
        },
      })
      .modal('show');
    },
    ownTheLoop (loop) {
      return loop.user.userId == firebase.auth().currentUser.uid;
    },
    toggleBookmark () {
      const {title, video_id} = this.player.getVideoData();
      if (this.isBookmarked) {
        ga('send', {
          hitType: 'event',
          eventCategory: 'looper',
          eventAction: 'removeBookmark',
        });
        removeBookmark({
          videoId: video_id,
          userId: firebase.auth().currentUser.uid,
        });
        this.isBookmarked = false;
      } else {
        ga('send', {
          hitType: 'event',
          eventCategory: 'looper',
          eventAction: 'addBookmark',
        });
        addBookmark({
          videoId: video_id,
          title,
          userId: firebase.auth().currentUser.uid,
        });
        this.isBookmarked = true;
      }
    },
  },
  components: {
    SearchBar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#player {
  max-width: 100%;
  height: 30vh;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  #player {
    height: 40vh;
  }
}

@media only screen and (min-width: 992px) {
  #player {
    height: 50vh;
  }
}

.ab-bar-bg {
  width: 100%;
  height: 6px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0px!important;
  background-color: lightgray;
  border-radius: 4px;
}

</style>
