<template>
  <div>
    <SearchBar></SearchBar>
    <div class="ui unstackable divided items">
      <router-link tag="div" v-for="video in results" :key="video.id.videoId" :to="{ name: 'looper', params: { videoId: video.id.videoId}}" class="item">
        <div class="tiny image">
          <img :src="video.snippet.thumbnails.default.url">
        </div>
        <div class="content">
          <div class="header">{{video.snippet.title}}</div>
          <!-- <div class="description">
            <p>{{video.snippet.description}}</p>
          </div> -->
          <div class="meta">
            <span>{{formatDateTime(video.snippet.publishedAt)}}</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="ui divider"></div>
    <button class="fluid ui button" :class="{'loading': isLoading}" :disabled="isLoading" @click="loadMore">載入更多</button>
  </div>
</template>

<script>
import SearchBar from './SearchBar';
import axios from 'axios';
import moment from 'moment';

export default {
  data () {
    return {
      results: [],
      nextPageToken: undefined,
      isLoading: false,
    };
  },
  mounted () {
    this.search(this.$route.params.keyword);
  },
  methods: {
    doSearch ({ keyword, pageToken }) {
      document.title = `re:Play - ${keyword}`;
      (async () => {
        this.isLoading = true;
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            type: 'video',
            q: keyword,
            key: 'AIzaSyDqg7QFJW2W0p719dxGK4RTEttDGT0bKaQ',
            maxResults: 25,
            pageToken,
          },
        });
        this.results = this.results || [];
        response.data.items.forEach((video) => this.results.push(video));
        this.nextPageToken = response.data.nextPageToken;
        this.isLoading = false;
      })()
      .catch(() => {

      });
    },
    search (keyword) {
      this.results = [];
      this.nextPageToken = undefined;
      this.doSearch({keyword});
    },
    formatDateTime (publishedAt) {
      return moment(publishedAt).fromNow();
    },
    loadMore () {
      this.doSearch({keyword: this.$route.params.keyword, pageToken: this.nextPageToken});
    },
  },
  watch: {
    '$route': function() {
      this.search(this.$route.params.keyword);
    },
  },
  components: {
    SearchBar,
  },
}
</script>

<style>
.ui.items>.item>.content>div.header, .ui.items>.item>.content>div.description {
    color: rgba(255,255,255,.85);
}
.ui.items>.item .extra, .ui.items>.item .meta {
    color: rgba(255,255,255,.6);
}

.ui.divided.items>.item {
    border-top-color: rgba(226, 224, 222, 0.40);
}
</style>
