<template>
  <div>
    <div class="ui fluid inverted action input">
      <input type="input" :placeholder="$t('Search video by usign keyword or paste YouTube video link')" v-model="input" @keyup.enter="go">
      <!-- <i class="delete icon" @click.stop.prevent="clear"></i> -->
      <button v-if="input" class="ui small button" @click="clear"><i class="delete icon" @click="clear"></i></button>
      <button class="ui inverted grey button" @click="go">{{$t('Loop It')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: undefined,
    };
  },
  computed: {
    videoId () {
      let matches = /v=([^&]*)&?/gm.exec(this.input);
      let videoId = matches && matches[1];
      if (videoId) {
        return videoId;
      }
      matches = /youtu\.be\/([^?]*)/gm.exec(this.input);
      videoId = matches && matches[1];
      if (videoId) {
        return videoId;
      }
    },
  },
  methods: {
    clear () {
      this.input = undefined;
    },
    go () {
      if (this.videoId) {
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'search',
          eventAction: 'search by url',
        });
        return this.$router.push({ name: 'looper', params: { videoId: this.videoId }});
      } else {
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'search',
          eventAction: 'search by keyword',
          eventLabel: this.input,
        });
        return this.$router.push({ name: 'searchResults', params: { keyword: this.input }});
      }
    },
  },
};
</script>
