<template>
  <div>
    <SearchBar></SearchBar>
    <div class="ui info message">
      <p>{{$t('re:Play can repeat certain part of YouTube video.', {keySeparator:'%', nsSeparator:'`'})}}</p>
      <p>{{$t('You can use re:Play to practice the instrument, such as guitar, learn language and you name it.', {keySeparator:'%', nsSeparator:'`'})}}</p>
    </div>
    <div class="ui warning message">
      <p>{{$t('We can keep data in browser temparally. To preserve data or share data across different devices, please sign in.', {keySeparator:'%', nsSeparator:'`'})}}</p>
    </div>
    <div class="ui warning message">
      <p>{{$t(`No AD now, 'cause re:Play is in beta period, LOL`, {nsSeparator:'`'})}}</p>
    </div>
    <div class="ui bottom fixed menu">
        <a class="ui item" href="mailto:support@jamx.works?subject=問題回報或建議"><i class="bullhorn icon"></i>{{$t('Feedback')}}</a>
        <a class="ui item" href="privacy.html">{{$t('Privacy Policy')}}</a>
    </div>
  </div>
</template>

<script>
import SearchBar from './SearchBar';

export default {
  components: {
    SearchBar,
  },
  mounted () {
    document.title = `re:Play - ${this.$t('Home')}`;
    document.dispatchEvent(new Event('render-event'))
  },
};
</script>
