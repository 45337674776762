<template>
  <div id="app">
    <div class="ui top fixed inverted replay menu">
      <router-link class="item" to="/" exact>
        <i class="refresh icon"></i><span class="small-mobile hidden">re:Play</span>
      </router-link>
      <router-link class="item" to="/bookmarks" linkActiveClass="active">
        <i class="bookmark icon"></i>{{$t('Collection')}}
      </router-link>
      <router-link class="item" to="/history" linkActiveClass="active">
        <i class="history icon"></i>{{$t('History')}}
      </router-link>
      <div class="right menu">
        <div v-if="isSignedIn" class="item" @click="signOut">
          <div class="ui button">{{$t('Sign Out')}}</div>
        </div>
        <div v-else class="item">
          <div class="ui primary button" @click="signIn">{{$t('Sign In')}}</div>
        </div>
      </div>
    </div>
    <main class="ui container">
        <router-view></router-view>
    </main>
  </div>
</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'app',
  data () {
    return {
      isSignedIn: false,
    };
  },
  mounted () {
    // eslint-disable-next-line no-unused-vars
    firebase.auth().onAuthStateChanged((user) => {
      this.isSignedIn = firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous;
      // eslint-disable-next-line no-console
      console.log('App.onAuthStateChanged isSignedIn', this.isSignedIn);
    });
  },
  methods: {
    signOut () {
      firebase.auth().signOut()
      .then(() => {
        // TODO: try to prevent refresh
        location.reload();
        // return firebase.auth().signInAnonymously().then(() => {
        //   console.log('onAuthStateChanged signInAnonymously done');
        // });
      });
    },
    signIn () {
      if (this.$router.currentRoute.name != 'signIn') {
        this.$router.push({ name: 'signIn', params: { next: this.$router.currentRoute.path }});
      }
    },
  },
}
</script>

<style>
main {
  padding-top: 20px;
}
@media only screen and (max-width: 375px) {
  .small-mobile.hidden {
    display: none !important;
  }
}
@media only screen and (max-width: 320px) {
  .ui.replay.menu .item {
    padding-left: 0.428em;
    padding-right: 0.428em;
  }
}
</style>
