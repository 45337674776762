<template>
  <div id="firebaseui-auth-container">
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
const firebaseui = require('../firebaseui/npm__zh_tw');

export default {
  mounted () {
    document.title = `re:Play - ${this.$t('Sign In')}`;    
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    const isSignedIn = firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous;
    if (!isSignedIn || ui.isPendingRedirect()) {
      const anonymousUser = firebase.auth().currentUser;
      const signInSuccessUrl = location.href;
      ui.start('#firebaseui-auth-container', {
        tosUrl: '/tos.html',
        privacyPolicyUrl: '/privacy.html',
        signInSuccessUrl,
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            forceSameDevice: true,
            emailLinkSignIn: function() {
              return {
                url: location.href,
                handleCodeInApp: true,
              };
            },
          },
        ],
        autoUpgradeAnonymousUsers: true,
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            // eslint-disable-next-line no-console
            console.log('signInSuccessWithAuthResult: redirectUrl', redirectUrl);
            this.doRedirect();
            return false;
          },
          // signInFailure callback must be provided to handle merge conflicts which
          // occur when an existing credential is linked to an anonymous user.
          signInFailure: (error) => {
            // eslint-disable-next-line no-console
            console.log('signInFailure', error);
            // For merge conflicts, the error.code will be
            // 'firebaseui/anonymous-upgrade-merge-conflict'.
            if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
              return Promise.resolve();
            }
            // The credential the user tried to sign in with.
            var cred = error.credential;
            // Copy data from anonymous user to permanent user and delete anonymous
            // user.
            // ...
            // Finish sign-in after data is copied.
            return firebase.auth().signInAndRetrieveDataWithCredential(cred)
              .then(() => {
                // eslint-disable-next-line no-console
                console.log('try to delete anonymousUser', anonymousUser.uid);                
                if (anonymousUser) {
                  anonymousUser.delete();
                }
              })
              .then(() => {
                this.doRedirect();
              });
          },
        },
      });
    } else {
      this.doRedirect();
    }
  },
  methods: {
    doRedirect () {
      let next = this.$route.params.next || '/';
      if (next.indexOf('/') == -1) {
        next = '/' + next;
      }
      this.$router.replace({ path: next });
    },
  },
}
</script>

<style>
.firebaseui-container.firebaseui-id-page-callback {
  background-color: black;
}
</style>
