<template>
  <div class="ui dimmable">
    <div v-if="bookmarks.length > 0" class="ui relaxed unstackable divided items">
      <router-link tag="div" v-for="(b, index) in bookmarks" :key="b.videoId" :to="{ name: 'looper', params: { videoId: b.videoId}}" class="item">
        <div class="tiny image">
          <img :src="`https://img.youtube.com/vi/${b.videoId}/default.jpg`">
        </div>
        <div class="content">
          <div class="description">{{b.title}}</div>
          <div class="extra">
            <button class="ui right floated red icon button" @click.stop="removeBookmark(index, b.videoId)">
              <i class="trash alternate icon"></i>
            </button>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else class="ui black message">
      <p>{{$t('Nothing in the Collection')}}</p>
    </div>
    <div class="ui modal" ref="confirmModal">
      <div class="header">{{$t('Remove from collection')}}</div>
      <div class="content">
        <p>{{$t('Are you sure you want to remove this video from the collection?')}}</p>
      </div>
      <div class="actions">
        <div class="ui cancel button">{{$t('Cancel')}}</div>
        <div class="ui red approve button">{{$t('Remove')}}</div>
      </div>
    </div>
    <div v-if="loading" class="ui active dimmer">
      <div class="ui loader"></div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { removeBookmark, getBookmarks } from '../bookmarks';

export default {
  name: 'Bookmarks',
  data () {
    return {
      bookmarks: [],
      loading: false,
    };
  },
  mounted () {
    document.title = `re:Play - ${this.$t('Collection')}`;
    const uid = firebase.auth().currentUser.uid;
    (async () => {
      this.loading = true;
      const querySnapshot = await getBookmarks(uid);      
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.bookmarks.push(doc.data());
      });
    })()
    .catch((err) => {
      // eslint-disable-next-line
      console.error(err)
    })
    .then(() => this.loading = false);
  },
  methods: {
    removeBookmark(index, videoId) {
      window.$(this.$refs.confirmModal).modal({
        closable: false,
        onDeny : () => {},
        onApprove : () => {
          this.doRemoveBookmark(index, videoId);
        },
      })
      .modal('show');
    },
    doRemoveBookmark(index, videoId) {
      const bookmark = this.bookmarks.splice(index, 1)[0];
      if (bookmark.videoId == videoId) { // sanity check
        removeBookmark({
          videoId,
          userId: firebase.auth().currentUser.uid,
        });
      }
    },
  },
}
</script>

<style>
.ui.items>.item>.content>div.header {
  color: rgba(255,255,255,.85);
}
.ui.items>.item .extra, .ui.items>.item .meta {
  color: rgba(255,255,255,.6);
}
.ui.divided.items>.item {
  border-top-color: rgba(226, 224, 222, 0.40);
}
.ui.modal>.content {
  color: black;
}
</style>
