<template>
  <div class="ui dimmable">
    <div v-if="history.length > 0" class="ui relaxed unstackable divided items">
      <router-link tag="div" v-for="h in history" :key="h.videoId" :to="{ name: 'looper', params: { videoId: h.videoId}}" class="item">
        <div class="tiny image">
          <img :src="`https://img.youtube.com/vi/${h.videoId}/default.jpg`">
        </div>
        <div class="content">
          <div class="description">{{h.title}}</div>
          <div class="meta">
            <span>{{formatDateTime(h.lastVisited)}}</span>
          </div>
          <!-- <div class="description">
            <p></p>
          </div>
          <div class="extra">
            {{formatDateTime(h.lastVisited)}}
          </div> -->
        </div>
      </router-link>
    </div>
    <div v-else class="ui black message">
      <p>{{$t('No history')}}</p>
    </div>
    <div v-if="loading" class="ui active dimmer">
      <div class="ui loader"></div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import { getHistory } from '../history';

export default {
  name: 'Hisotry',
  data () {
    return {
      history: [],
      loading: false,
    };
  },
  mounted () {
    document.title = `re:Play - ${this.$t('History')}`;
    const uid = firebase.auth().currentUser.uid;
    (async () => {
      this.loading = true;
      const querySnapshot = await getHistory(uid);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.history.push(doc.data());
      });
    })()
    .catch(() => {})
    .then(() => this.loading = false);
  },
  methods: {
    formatDateTime (lastVisited) {
      return moment(lastVisited.seconds*1000).fromNow();
    },
  },
}
</script>

<style>
.ui.items>.item>.content>div.header {
  color: rgba(255,255,255,.85);
}
.ui.items>.item .extra, .ui.items>.item .meta {
  color: rgba(255,255,255,.6);
}
.ui.divided.items>.item {
  border-top-color: rgba(226, 224, 222, 0.40);
}
</style>
