import firebase from 'firebase/app';
import 'firebase/firestore';

const uploadLoop = ({ user, name, video, a, b }) => {
  const db = firebase.firestore();
  const loop = {
    name,
    video,
    a,
    b,
    user,
    created: new Date(),
    upvote: 0,
  };
  return (async () => {
    const data = await db.collection('videos').doc(video.videoId).collection('loops').add(loop);
    await db.collection('users').doc(user.userId).collection('loops').doc(data.id).set(loop);
    loop.id = data.id;
    return loop;
  })();
};

const getTopLoops = ({ videoId }) => {
  const db = firebase.firestore();
  return db.collection('videos').doc(videoId).collection('loops').orderBy('a', 'asc').get();
};

const removeLoop = (loop) => {
  const { user: { userId }, video: { videoId } } = loop;
  const db = firebase.firestore();
  return Promise.all([
    db.collection('videos').doc(videoId).collection('loops').doc(loop.id).delete(),
    (async () => {
      const userLoops = db.collection('users').doc(userId).collection('loops');
      await userLoops.doc(loop.id).delete();
      const loops = await userLoops
        .where('a', '==', loop.a)
        .where('b', '==', loop.b)
        .where('created', '==', loop.created)
        .where('name', '==', loop.name)
        .get();
      if (loops.docs.length > 0) {
        return loops.docs[0].ref.delete();
      }
    })(),
  ]).then(() => {
    return loop;
  });
};

export {
  uploadLoop,
  getTopLoops,
  removeLoop,
};
