import firebase from 'firebase/app';
import 'firebase/firestore';

const addBookmark = ({ videoId, title, userId }) => {
  const db = firebase.firestore();
  return db.collection('users').doc(userId).collection('bookmarks').doc(videoId).set({
    videoId,
    title,
    created: new Date(),
  });
};

const removeBookmark = ({ videoId, userId }) => {
  const db = firebase.firestore();
  return db.collection('users').doc(userId).collection('bookmarks').doc(videoId).delete();
};

const isBookmarked = async ({ videoId, userId }) => {
  const db = firebase.firestore();
  const docSnapshot = await db.collection('users').doc(userId).collection('bookmarks').doc(videoId).get();
  return docSnapshot.exists;
};

const getBookmarks = (userId) => {
  const db = firebase.firestore();
  return db.collection('users').doc(userId).collection('bookmarks').orderBy('created').get();
};

export {
  addBookmark,
  removeBookmark,
  isBookmarked,
  getBookmarks,
};
