import VueRouter from 'vue-router'
import Main from './components/Main'
import YouTubePlayer from './components/YouTubePlayer'
import History from './components/History'
import SearchResults from './components/SearchResults'
import Bookmarks from './components/Bookmarks'
import SignIn from './components/SignIn'

const routes = [
  { path: '/', component: Main },
  { path: '/loop/:videoId', name: 'looper', component: YouTubePlayer },
  { path: '/history', name: 'history', component: History },
  { path: '/search/:keyword', name: 'searchResults', component: SearchResults },
  { path: '/bookmarks', name: 'bookmarks', component: Bookmarks },
  { path: '/signin', component: SignIn },
  { path: '/signin/:next', name: 'signIn', component: SignIn },
  { path: '/signin//:next*', component: SignIn }, // workaround accountchooser url encoding issue of field clientCallbackUrl
];
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
});

export default router;
