import firebase from 'firebase/app';
import 'firebase/firestore';

const updateHistory = ({ videoId, title, userId }) => {
  const db = firebase.firestore();
  db.collection('users').doc(userId).collection('history').doc(videoId).set({
    videoId,
    lastVisited: new Date(),
    title,
  });
};

const getHistory = (userId) => {
  const db = firebase.firestore();
  return db.collection('users').doc(userId).collection('history').orderBy('lastVisited', 'desc').get();
}

export {
  updateHistory,
  getHistory,
};
