import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './routes'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import VueI18Next from '@panter/vue-i18next';

import '../public/semantic-ui/components/button.min.css';
import '../public/semantic-ui/components/container.min.css';
import '../public/semantic-ui/components/divider.min.css';
import '../public/semantic-ui/components/grid.min.css';
import '../public/semantic-ui/components/icon.min.css';
import '../public/semantic-ui/components/image.min.css';
import '../public/semantic-ui/components/input.min.css';
import '../public/semantic-ui/components/item.min.css';
import '../public/semantic-ui/components/label.min.css';
import '../public/semantic-ui/components/menu.min.css';
import '../public/semantic-ui/components/message.min.css';
import '../public/semantic-ui/components/modal.min.css';
import '../public/semantic-ui/components/search.min.css';
import '../public/semantic-ui/components/tab.min.css';
import '../public/semantic-ui/components/transition.min.css';

Vue.use(VueRouter);
Vue.use(VueI18Next);
i18next
  .use(LngDetector)
  .use(XHR)
  .init({

  });
const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false

var config = {
  apiKey: "AIzaSyDqg7QFJW2W0p719dxGK4RTEttDGT0bKaQ",
  authDomain: "replay.jamx.works",
  databaseURL: "https://youloop.firebaseio.com",
  projectId: "youloop",
  storageBucket: "",
  messagingSenderId: "839294719570"
};
firebase.initializeApp(config);

const db = firebase.firestore();
// Disable deprecated features
db.settings({
  timestampsInSnapshots: true
});
router.afterEach((to, from) => {
  if (to.path != from.path &&
    to.matched && to.matched[0]) {
    const ga = window.ga;
    let page = to.matched[0].path;
    if (page == '') {
      page = '/';
    }
    ga('set', 'page', page);
    ga('send', 'pageview');  
  }
});
const initVue = () => {
  new Vue({
    router,
    i18n,
    render: h => h(App),
  }).$mount('#app');
};

const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
  // eslint-disable-next-line no-console
  console.log('onAuthStateChanged', user && user.uid, 'isAnonymous', user && user.isAnonymous, user);
  if (user) {
    // User is signed in.
    initVue();
    unsubscribe();
  } else {
    firebase.auth().signInAnonymously().then(() => {
      // eslint-disable-next-line no-console
      console.log('onAuthStateChanged signInAnonymously done');
    })
    .catch(function(error) {
      // eslint-disable-next-line no-console
      console.log('onAuthStateChanged signInAnonymously failed', error);
      error;
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // ...
    });
  }  
});
